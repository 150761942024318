<div class="bg">
    <div class="container">
        <div fxLayout="column" class="main">
            <div fxFlex="10" class="title center">
                <h1>すたでぃばんく利用登録</h1>
            </div>
            <div fxFlex="70">
                <mat-card class="vcenter">
                    <mat-card-content>
                        <div class="seats">
                            <mat-grid-list cols="{{colNum}}" rowHeight="{{rowHeight}}">
                                <mat-grid-tile *ngFor="let position of positions" colspan="1" rowspan="1"
                                    [style.background]="checkSelected(position)" (click)="clickTile(position)">
                                    {{getPositionName(position)}}
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            
            <div fxFlex="5"></div>
            <div fxFlex="15">
                <mat-card class="vcenter">
                    <mat-card-content>
                        <div fxLayout="row">
                            <div fxFlex="25" fxLayout="row" class="vcenter">
                                <p>選択された座席:</p>
                                <button mat-flat-button [style.background]="selected.state.color" class="selected-seat">
                                    {{selected.position.seat.name}}
                                </button>
                            </div>
                            <div fxFlex="75" fxLayout="row" class="seat-status">
                                <p>利用状況を選択してください: </p>
                                <div *ngFor="let state of status">
                                    <button mat-raised-button [style.background-color]="state.color"
                                        (click)='clickUpdate(state.id)'>{{ state.name }}</button>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <!-- <button mat-raised-button color="warn" (click)='test()' >test</button> -->
    </div>
</div>