<app-nav>
  <div class="grid-container">
      <h1 class="mat-h1">座席設定</h1>
    <div class="row">
      <div class="container mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource" matSort class="mat-cell">

          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.name}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
            <mat-cell *matCellDef="let row" [style.background-color]="getColor(row.state.id)">
              {{getStatus(row.state.id)}}</mat-cell>
          </ng-container>

          <!-- actions -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>
              <button mat-icon-button color="primary" (click)="addNew()">
                <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
              </button>
              <span class="spacer"></span>
              Reload data:
              <button mat-icon-button (click)="refresh()">
                <mat-icon>refresh</mat-icon>
              </button>
            </mat-header-cell>

            <mat-cell *matCellDef="let row; let i=index;">
              <button mat-icon-button color="accent" (click)="startEdit(row)">
                <mat-icon aria-label="Edit">edit</mat-icon>
              </button>

              <button mat-icon-button color="accent" (click)="deleteItem(row)">
                <mat-icon aria-label="Delete">delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </div>

    </div>
  </div>
</app-nav>