<div class="center">
  <mat-card class="login-card">
    <mat-card-header class="login-header">
      <mat-card-title class="login-title">すたでぃばんく</mat-card-title>
    </mat-card-header>
    <mat-card-content class="login-content">
      <form (ngSubmit)="onSubmit()" class="login-form" #loginForm="ngForm">
        <mat-form-field>
          <input matInput placeholder="ログインID" id="username" [(ngModel)]="login.username" name="username"
            #username="ngModel" required>
          <mat-error *ngIf="username.errors?.required">必須入力です。</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <input [type]="hide ? 'text' : 'password'" matInput placeholder="パスワード" id="password"
            [(ngModel)]="login.password" name="password" #password="ngModel" required>
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="password.errors?.required">必須入力です。</mat-error>
        </mat-form-field>
        <br>
        <button type="submit" mat-raised-button color="primary" [disabled]="loginForm.invalid">Login</button>
      </form>
    </mat-card-content>
    <div class="login-error" *ngIf="loginE">認証情報が正しくありません。もう一度試してください。</div>
  </mat-card>
</div>
<!-- <amplify-authenticator></amplify-authenticator> -->