import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';

import { APIService } from 'app/API.service';
import { isNull } from 'util';

@Component({
  selector: 'app-open-setting',
  templateUrl: './open-setting.component.html',
  styleUrls: ['./open-setting.component.css']
})
export class OpenSettingComponent implements OnInit {

  opens: any[];
  times: any[];
  selectedTime: any;
  selectedOpen: any;
  otherTime = {
    id: '99',
    color: 'purple',
    open: 'その他',
    end: null
  };
  closedTime = {
    id: '0',
    color: 'red',
    open: '休館日',
    end: null
  };

  otherOpen: string;
  otherEnd: string;

  selectedRadio: any;
  minDate:Date;
  maxDate:Date;


  selectedDate: any;
  //minDate: string | null = null;

  datesToHighlight = [];

  constructor(private api: APIService,
    @Inject(LOCALE_ID) private locale: string) {
    this.opens = this.datesToHighlight;
    this.otherOpen = '00:00';
    this.otherEnd = '00:00';
    this.minDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(31);
    this.maxDate.setMonth(this.maxDate.getMonth() + 2);

    // this.startDate =  formatDate(this.today, "yyyyMMdd", this.locale);
    // this.endDate = formatDate(this.endday, "yyyyMMdd", this.locale);
    this.loadDate();
    this.subscriptionOpens();
  }

  ngOnInit() {
  }

  loadDate() {
    this.api.ListTimes(null, 100, null).then(times => {
      this.times = times.items.sort((a, b) => {
        return Number(a.id) - Number(b.id);
      });
      const openFileter = {
        id:{ge: formatDate(this.minDate, "yyyyMMdd", this.locale)}
      }
      this.api.ListOpens(openFileter, 1000, null).then(opens => {
        this.opens = opens.items;
        this.minDate = new Date();
        setTimeout(() => {
          this.minDate = new Date();
        }, 0);
      });
    });
  }

  onSelect(event) {
    this.selectedDate = event;
    this.selectedOpen = this.opens.find(open => open.id == formatDate(this.selectedDate, "yyyyMMdd", this.locale));
    if (this.selectedOpen == null) {
    }
    else {
      this.otherOpen = '00:00';
      this.otherEnd = '00:00';

      switch (this.selectedOpen.color) {
        case 'red':
          this.selectedTime = this.closedTime;
          break;
        case 'purple':
          this.selectedTime = this.otherTime;
          const other = this.selectedOpen.time.split('～');
          this.otherOpen = other[0];
          this.otherEnd = other[1];
          break;
        default:
          this.selectedTime = this.times.find(t => t.color == this.selectedOpen.color);
          break;
      }
    }
  }

  getHighlightColor(color: string){
    return 'open-' + color;
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      var highlightColor:string

      const highlightDate = this.opens.some(open => {
        const d = formatDate(date, "yyyyMMdd", this.locale);
        if (d == open.id) {
          highlightColor = this.getHighlightColor(open.color);
          return true;
        }
      });

      return highlightDate ? highlightColor : '';
    };
  }


  public onSubmit() {
    if (this.selectedDate == null) {
      return
    }

    var timeString: string;

    switch (this.selectedTime.id) {
      case '0':
        timeString = "休館日"
        break;
      case '99':
        timeString = this.otherOpen + '～' + this.otherEnd;
        break;
      default:
        timeString = this.selectedTime.open + '～' + this.selectedTime.end;
        break;
    }

    const updateOpen = {
      id: formatDate(this.selectedDate, "yyyyMMdd", this.locale),
      color: this.selectedTime.color,
      date: null,
      time: timeString,
      unixtime: Date.parse(this.selectedDate)/1000 + 86400
    };

    if (this.selectedOpen == null) {
      this.api.CreateOpen(updateOpen);
    }
    else {
      this.api.UpdateOpen(updateOpen);
    }
  }

  subscriptionOpens(): void {
    this.api.OnUpdateOpenListener.subscribe(result => {
      const open = result['value']['data']['onUpdateOpen']
      this.opens = this.opens.map(item => {
        if (item.id == open.id) {
          return open;
        }
        return item;
      });
      this.minDate = null;
      setTimeout(() => {
        this.minDate = new Date();
      }, 0);
    });
    this.api.OnCreateOpenListener.subscribe(result => {
      const open = result['value']['data']['onCreateOpen']
      this.opens.push(open);
      this.minDate = null;
      setTimeout(() => {
        this.minDate = new Date();
      }, 0);
    });
  }
}
