import { Component, OnInit } from '@angular/core';

import { isNull, isBoolean } from 'util';
import { APIService } from 'app/API.service';
import { Position } from '../models/position';
import { State } from '../models/state';
import { Seat } from '../models/seat';

import { Tile } from './services/Tile'
import { tilelist } from './services/tilelist'
import { state } from '@angular/animations';


@Component({
  selector: 'app-seat-regist',
  templateUrl: './seat-regist.component.html',
  styleUrls: ['./seat-regist.component.css']
})
export class SeatRegistComponent implements OnInit {

  private seats: Seat[];
  public status: State[];
  public positions : Position[];

  state0 = new State('0','','white')
  seat0 = new Seat('0', '', this.state0);
  position0 = new Position('0', this.seat0);

  public colNum: number;
  public rowNum: number;
  public rowHeight: string;
  public selected: any;

  private tileClass: string;


  constructor(private api: APIService) { 
    this.seats = [this.seat0]; 
    this.positions = [this.position0]; 
    this.status = [this.state0];
    this.selected = {position:this.position0, state: this.state0 };

    this.loadData();
    this.subscriptionSeats();
  }

  ngOnInit() {
  }

  public loadData() {
    this.api.ListParameters(null,100,null).then(parm =>{
      this.colNum = Number(parm.items.find(p => p.name == "colNumber").state);
      this.rowNum = Number(parm.items.find(p => p.name == "rowNumber").state);
      const height = 65/this.rowNum;
      this.rowHeight = String(height) + 'vh'
      this.api.ListSeats(null,100,null).then(seat =>{
        this.seats = seat.items;
        this.api.ListStates(null,100,null).then(states => {
          this.status = states.items;
          this.api.ListPositions(null,200,null).then(positions => {
            this.positions  = positions.items.sort((a,b)=>{
              return Number(a.id) - Number(b.id);
            });
          });
        });
      });
    });
  }

  clickTile(position: Position){
    if(isNull(position.seat)){
      return;
    }
    if(position.seat.id == '0'){
      return;
    }

    this.selected = {position:position, state:this.getSeatState(position)};
  }

  checkSelected(position: Position){
    if(this.selected.position.id == position.id){
      return 'yellow';
    }
    if(isNull(position.seat)){
      return 'white';
    }
    if(position.seat.id == '0'){
      return 'white';
    }

    return this.getSeatState(position).color;
  }

  getPositionName(position:Position){
    if(isNull(position.seat)){
      return '';
    }
    return position.seat.name
  }

  getSeatState(position:Position){
    if(isNull(position.seat)){
      return null;
    }
    return this.seats.find(s => s.id == position.seat.id).state
  }
  
  clickUpdate(status:string){
    this.statusChanged(this.selected.position.seat.id, this.selected.position.seat.name, status);
  }
  

  public statusChanged(id:string, name:string, status:string)
  {
    if(id == '0'){
      return
    }
    const updateSeat = {
      id: id.toString(),
			name: name,
			seatStateId: status,
    };
    this.api.UpdateSeat(updateSeat).then(test => {
      this.loadData();
    });

    this.selected = {position:this.position0, state: this.state0 };
  }

  
  subscriptionSeats(): void {
    this.api.OnUpdateSeatListener.subscribe(result => {
      const seat = result['value']['data']['onUpdateSeat']
      this.seats = this.seats.map(item => 
        {
          if(item.id == seat.id){
            return seat;
          }
          return item;
        });
    });
  }
}
