<div class="container">
  <h3 mat-dialog-title>Add new Seat</h3>

  <!--<form class="mat-dialog-content" (ngSubmit)="submit" #formControl="ngForm">-->
  <form class="mat-dialog-content" #formControl="ngForm">

    <div class="form">
      <mat-form-field color="accent">
        <input matInput #input class="form-control" placeholder="Id" [(ngModel)]="data.seat.id" name="id" required >
        <mat-error *ngIf="formControl.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <textarea matInput #input class="form-control" placeholder="Name" [(ngModel)]="data.seat.name" name="name" required ></textarea>
        <mat-error *ngIf="formControl.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>

    <div mat-dialog-actions>
      <button mat-button [type]="submit" [disabled]="!formControl.valid" [mat-dialog-close]="1" (click)="confirmAdd()">Save</button>
      <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    </div>
  </form>
</div>
