import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, from, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
 
import Amplify, { Auth } from 'aws-amplify';
 
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn: BehaviorSubject<boolean>;
  password: String;
  constructor(private router: Router) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }
 
  /** サインアップ */
  public signUp(username, password): Observable<any> {
    this.password = password;
    return from(Auth.signUp(username, password, username));
  }
 
  /** 検証 */
  public confirmSignUp(username, code): Observable<any> {
    return from(Auth.confirmSignUp(username, code));
  }
 
  /** ログイン */
  public signIn(username, password): Observable<any> {
    return from(Auth.signIn(username, password)).pipe(
      tap(() => this.loggedIn.next(true))
    );
  }
 
  /** ログインユーザ情報の取得 */
  public getData(): Observable<any> {
    return from(Auth.currentAuthenticatedUser());
  }
 
  /** idtokenを取得 */
  public getIdToken(): string {
    return Auth.currentSession()['__zone_symbol__value']['idToken']['jwtToken'];
  }
 
 
  /** ログイン状態の取得 */
  public isAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map(result => {
        this.loggedIn.next(true);
        return true;
      }),
      catchError(error => {
        this.loggedIn.next(false);
        return of(false);
      })
    );
  }
 
  /** ログアウト */
  public signOut() {
    from(Auth.signOut()).subscribe(
      result => {
        this.loggedIn.next(false);
        this.router.navigate(['/login']);
      },
      error => console.log(error)
    );
  }
}