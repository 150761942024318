import {Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {Seat} from '../models/seat';
import { State } from '../models/state';
import {AddDialogComponent} from './dialogs/add/add.dialog.component';
import {EditDialogComponent} from './dialogs/edit/edit.dialog.component';
import {DeleteDialogComponent} from './dialogs/delete/delete.dialog.component';
import {MatTableDataSource} from '@angular/material/table';
import { APIService } from '../API.service';
import { isNull } from 'util';

@Component({
  selector: 'app-seats-setting',
  templateUrl: './seats-setting.component.html',
  styleUrls: ['./seats-setting.component.css']
})
export class SeatsSettingComponent implements OnInit {
  displayedColumns = ['id', 'name', 'state', 'actions'];
  SeatData: Seat[];
  StateData: State[];
  dataSource: MatTableDataSource<Seat>;

  allTodos: any = [];

  State0 = new State('0','','');
  Seat0 = new Seat('0','',this.State0);

  constructor(public dialog: MatDialog,
              private api: APIService) {
                this.SeatData = [this.Seat0];
                this.StateData = [this.State0];
                this.dataSource = new MatTableDataSource<Seat>(this.SeatData);
                this.loadData();
                this.subscriptionSeats();
              }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  async ngOnInit() {
  }

  refresh() {
    this.loadData();
  }

  getStatus(id:string){
    if(isNull(id)){
      return '';
    }
    return this.StateData.find(state => state.id == id).name;
  }
  getColor(id:string){
    if(isNull(id)){
      return '';
    }
    return this.StateData.find(state => state.id == id).color;
  }

  addNew() {
    const dialogRef = this.dialog.open(AddDialogComponent, {
      data: {seat: this.Seat0 }
    });
  }

  startEdit(seat: Seat) {
    const dialogRef = this.dialog.open(EditDialogComponent, {
      data: {seat: seat }
    });
  }

  deleteItem(seat: Seat) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {seat: seat }
    });
  }

  public loadData() {
    this.api.ListSeats(null,100,null).then(data => {
      this.SeatData = data.items;
      this.SeatData.sort((a,b)=>{
        return Number(a.id) - Number(b.id);
      })
      this.api.ListStates(null,100,null).then(states =>{
        this.StateData = states.items;

        this.dataSource = new MatTableDataSource<Seat>(this.SeatData);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 0);
      })
    });
  }
  

  subscriptionSeats(): void {
    this.api.OnUpdateSeatListener.subscribe(result => {
      const seat = result['value']['data']['onUpdateSeat']
      this.SeatData = this.SeatData.map(item => {
        if (item.id == seat.id) {
          return seat;
        }
        return item;
      });
    });
    this.api.OnCreateSeatListener.subscribe(result => {
      this.refresh();
    });
    this.api.OnDeleteSeatListener.subscribe(result =>{
      this.refresh();
    });
  }
}
