import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

import { SeatsSettingComponent } from './seats-setting/seats-setting.component';
import { OpenSettingComponent } from './open-setting/open-setting.component';
import { FileSettingComponent } from './file-setting/file-setting.component';
import { ShowInquiryComponent } from './show-inquiry/show-inquiry.component';
import { SeatInquiryComponent } from './seat-inquiry/seat-inquiry.component';
import { SeatRegistComponent} from './seat-regist/seat-regist.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutSettingComponent} from './layout-setting/layout-setting.component';

const routes: Routes = [
  //{path:"", redirectTo: 'show-status', pathMatch: 'full'},
  {path:"", redirectTo: 'show-inquiry', pathMatch: 'full'},
  {path:"show-inquiry", component: ShowInquiryComponent},
  {path:"seat-inquiry", component: SeatInquiryComponent},
  {path:"login", component: LoginComponent},
  {path:"seat-regist", component: SeatRegistComponent, canActivate: [AuthGuard]},
  {path:"dashboard", component:DashboardComponent, canActivate: [AuthGuard]},
  {path:"seats-setting", component: SeatsSettingComponent, canActivate: [AuthGuard]},
  {path:"open-setting", component: OpenSettingComponent, canActivate: [AuthGuard]},
  {path:"file-setting", component: FileSettingComponent, canActivate: [AuthGuard]},
  {path:"layout-setting", component: LayoutSettingComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: 'show-inquiry', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
