<div class="container">
  <h3 mat-dialog-title>Are you sure?</h3>
  <div mat-dialog-content>
    Id: {{data.seat.id}}
    <p></p>
    Name: {{data.seat.name}}
    <p></p>
  </div>

  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="1" (click)="confirmDelete()">Delete</button>
    <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
  </div>
</div>
