//Common
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

//Angular Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSpinner } from '@angular/material/progress-spinner';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';


//User
import { SeatsSettingComponent } from './seats-setting/seats-setting.component';
import { ShowInquiryComponent } from './show-inquiry/show-inquiry.component';
import { SeatRegistComponent } from './seat-regist/seat-regist.component';
import { AddDialogComponent } from './seats-setting/dialogs/add/add.dialog.component';
import { EditDialogComponent } from './seats-setting/dialogs/edit/edit.dialog.component';
import { DeleteDialogComponent } from './seats-setting/dialogs/delete/delete.dialog.component';

//environment import
//import { environment } from "src/environments/environment";

//Amplify
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { from } from 'rxjs';
import { OpenSettingComponent } from './open-setting/open-setting.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutModule } from '@angular/cdk/layout';
import { NavComponent } from './nav/nav.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FileSettingComponent } from './file-setting/file-setting.component';
import { ShowNavComponent } from './show-nav/show-nav.component';
import { LayoutSettingComponent } from './layout-setting/layout-setting.component';
import { SeatInquiryComponent } from './seat-inquiry/seat-inquiry.component';

@NgModule({
  declarations: [
    AppComponent,
    SeatsSettingComponent,
    ShowInquiryComponent,
    SeatRegistComponent,
    AddDialogComponent,
    EditDialogComponent,
    DeleteDialogComponent,
    OpenSettingComponent,
    LoginComponent,
    DashboardComponent,
    NavComponent,
    FileSettingComponent,
    ShowNavComponent,
    LayoutSettingComponent,
    SeatInquiryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatCardModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatDatepickerModule,

    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTabsModule,
    MatTooltipModule,

    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    CommonModule,
    AmplifyAngularModule,
    FlexLayoutModule,


    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  entryComponents: [
    AddDialogComponent,
    EditDialogComponent,
    DeleteDialogComponent,
    MatSpinner
  ],
  providers: [AmplifyService],
  bootstrap: [AppComponent]
})
export class AppModule { }