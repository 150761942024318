import { Component, OnInit } from '@angular/core';
// 追加
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';

export class PrivateAPIlogin {
  constructor(public username: string, public password: string) {}
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  login = new PrivateAPIlogin('', '');
  loginE = false;
  public hide;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  onSubmit() {
    // this.auth.signIn(this.username, this.password)
    this.auth.signIn(this.login.username, this.login.password)
      .subscribe(
        result => {
          this.router.navigateByUrl(this.returnUrl);
        },
        error => {
          this.loginE = true;
          console.log(error);
        });
  }
}