<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="!(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="!(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async)">
    <mat-toolbar>関連ファイル一覧</mat-toolbar>
    <mat-nav-list>
      <mat-list-item *ngFor="let file of uploadFiles">
         <a matLine class="filename" matTooltip="{{file.key}}" href="javascript:void(0)" (click)="downloadItem(file)">{{file.key}}</a>
         <button mat-icon-button (click)="downloadItem(file)">
            <mat-icon>get_app</mat-icon>
         </button>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content color="white">
    <mat-toolbar color="white">

      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="!(isHandset$ | async)">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <!-- <span>awsorihime</span> -->
      
      <div class="title-center" fxLayout="column">
        <div class="title-row" fxFlex="50">すたでぃばんく</div>
        <div class="title-row" fxFlex="50">空席状況</div>
    </div>
    </mat-toolbar>
    <!-- Add Content Here -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
