<app-show-nav>
    <div class="bg">
        <div class="container">
            <div fxLayout="column" class="main">
                <!-- <div fxFlex="15">
                    <div class="title center">
                        <div>
                            すたでぃばんく<br>空席状況</div>
                    </div>
                </div> -->
                <div fxFlex="8" class="today center">
                    {{today | date:"yyyy年MM月dd日(E)"}}
                </div>
                <div fxFlex="8" class='open-time center'>
                    開館時刻：{{openTime}}
                </div>
                <div fxFlex="40" class="images center">
                    <img [src]="images">
                </div>
                <div fxFlex="10">
                    <div fxFlex="row">
                        <div fxFlex="40" class="ato" *ngIf="atoVisible">
                            あと
                        </div>
                    </div>
                </div>
                <div fxFlex="20" class="full">
                    <div class="seat-num center full" *ngIf="numVisible">
                        <div [class]="emptyNumClass">{{emptyNum}}</div>
                        <div class="slash">/</div>
                        <div class="all-num">{{allNum}}</div>
                    </div>
                </div>                
            </div>
        </div>
        <div class="footer" style="text-align: center;">
            <button mat-raised-button color="primary" (click)='onNavigate()'>座席状況を表示</button>
        </div>
        <button mat-fab color="warn" (click)='loadData()' class="md-fab-reload">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</app-show-nav>