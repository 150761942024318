import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';

import { isNull, isBoolean } from 'util';
import { APIService } from 'app/API.service';
import { Position } from '../models/position';
import { State } from '../models/state';
import { Seat } from '../models/seat';
@Component({
  selector: 'app-layout-setting',
  templateUrl: './layout-setting.component.html',
  styleUrls: ['./layout-setting.component.css']
})
export class LayoutSettingComponent implements OnInit {

  public seats: Seat[];
  public status: State[];
  public positions: Position[];
  public positions0: Position[];

  state0 = new State('0', '', 'white')
  seat0 = new Seat('0', '', this.state0);
  position0 = new Position('0', this.seat0);


  public selected: any;

  private tileClass: string;

  public colNumber: number = 10;
  public rowNumber: number = 10;
  public colNum: number;
  public rowNum: number;


  public selectedSeat = new Seat('0', '', this.state0);

  constructor(private api: APIService) {
    this.seats = [this.seat0];
    this.positions = [this.position0];
    this.status = [this.state0];
    this.selected = { position: this.position0, state: this.state0 };

    this.loadData();
  }

  ngOnInit() {
  }

  public loadData() {
    this.api.ListParameters(null, 100, null).then(parm => {
      this.colNum = Number(parm.items.find(p => p.name == "colNumber").state);
      this.rowNum = Number(parm.items.find(p => p.name == "rowNumber").state);
      this.colNumber = Number(parm.items.find(p => p.name == "colNumber").state);
      this.rowNumber = Number(parm.items.find(p => p.name == "rowNumber").state);
      this.api.ListSeats(null, 100, null).then(seat => {
        this.seats = seat.items.sort((a, b) => {
          return Number(a.id) - Number(b.id);
        });
        this.api.ListStates(null, 100, null).then(states => {
          this.status = states.items;
          this.api.ListPositions(null, 200, null).then(positions => {
            this.positions0 = positions.items.sort((a, b) => {
              return Number(a.id) - Number(b.id);
            });
            this.positions = this.positions0.concat();
          })
        });
      });

    })
  }

  clickTile(position: Position) {
    this.selected = { position: position, state: this.getSeatState(position) };
  }

  checkSelected(position: Position) {
    if (this.selected.position.id == position.id) {
      return 'yellow';
    }
    if (isNull(position.seat)) {
      return 'lightgray';
    }
    if (position.seat.id == '0') {
      return 'lightgray';
    }

    return 'lightblue';
  }

  getPositionName(position: Position) {
    if (isNull(position.seat)) {
      return '';
    }
    return position.seat.name
  }

  getSeatState(position: Position) {
    if (isNull(position.seat)) {
      return null;
    }
    return this.seats.find(s => s.id == position.seat.id).state
  }

  onSubmit() {
    this.colNum = this.colNumber;
    this.rowNum = this.rowNumber;

    const seatLength = this.colNum * this.rowNum;
    let positionLength = this.positions.length;

    if (positionLength >= seatLength) {
      this.positions = this.positions.filter(pos => Number(pos.id) <= seatLength)
    }
    else {
      while (seatLength > positionLength) {
        positionLength = positionLength + 1;
        this.positions.push(new Position(String(positionLength), null));
      }
    }
  }

  getOption(seat: Seat): string {
    const id = (seat.id + '    ').slice(0, 4);
    const name = seat.name;
    return id + name;
  }

  onSeat() {
    if (this.selected.position.id != '0') {
      this.positions[Number(this.selected.position.id) - 1].seat = this.selectedSeat;
    }
    this.selectedSeat = null;
  }

  public async clickUpdate() {
    const positionLength = this.positions.length;
    const positionLength0 = this.positions0.length;

    if (positionLength <= positionLength0) {

      const update = this.positions0.map(pos0 => {
        const pos = this.positions.some(p => pos0.id == p.id);
        if(pos){
          this.updatePosition(this.positions.find(p => pos0.id == p.id));
        }else{
          this.deletePosition(pos0);
        }
      });
      await Promise.all(update);
    }
    else{

      const create = this.positions.map(pos => {
        const pos0 = this.positions0.some(p0 => pos.id == p0.id);
        if(pos0){
          this.updatePosition(pos);
        }else{
          this.createPosition(pos);
        }
      });
      await Promise.all(create);
    }
    await this.updateParamater();
    this.positions0 = this.positions.slice(0);
    this.colNumber = this.colNum;
    this.rowNumber = this.rowNum;

  }

  public resetData(){
    this.positions = this.positions0.slice(0);
    this
  }

  public deletePosition(pos: Position): any {
    const delPos = {
      id: pos.id
    }
    return this.api.DeletePosition(delPos).then(v => {
      console.log(delPos);
      console.log("delend" + delPos.id);
    });;
  }

  public createPosition(pos: Position): any {
    const crePos = {
      id: pos.id,
      positionSeatId: isNull(pos.seat) ? '0' : pos.seat.id
    }
    this.api.CreatePosition(crePos).then(v => {
      console.log(crePos);
      console.log("creend" + crePos.id);
    });
  }

  public updatePosition(pos: Position) {
    const updPos = {
      id: pos.id,
      positionSeatId: isNull(pos.seat) ? '0' : pos.seat.id
    }
    this.api.UpdatePosition(updPos).then(v => {
      console.log(updPos);
      console.log("updend" + updPos.id);
    });
  }

  public updateParamater(){
    const updCol = {
      id: "1",
      name: "colNumber",
      state: String(this.colNum)
    }
    this.api.UpdateParameter(updCol);
    const updRow = {
      id: "2",
      name: "rowNumber",
      state: String(this.rowNum)
    }
    this.api.UpdateParameter(updRow); 
  }

  public clickReset(){
    this.loadData();
  }
}
