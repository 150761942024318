/* tslint:disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export type CreatePositionInput = {
  id?: string | null;
  positionSeatId?: string | null;
};

export type UpdatePositionInput = {
  id: string;
  positionSeatId?: string | null;
};

export type DeletePositionInput = {
  id?: string | null;
};

export type CreateSeatInput = {
  id?: string | null;
  name?: string | null;
  seatStateId?: string | null;
};

export type UpdateSeatInput = {
  id: string;
  name?: string | null;
  seatStateId?: string | null;
};

export type DeleteSeatInput = {
  id?: string | null;
};

export type CreateStateInput = {
  id?: string | null;
  name?: string | null;
  color?: string | null;
};

export type UpdateStateInput = {
  id: string;
  name?: string | null;
  color?: string | null;
};

export type DeleteStateInput = {
  id?: string | null;
};

export type CreateOpenInput = {
  id?: string | null;
  date?: string | null;
  time?: string | null;
  color?: string | null;
  unixtime?: number | null;
};

export type UpdateOpenInput = {
  id: string;
  date?: string | null;
  time?: string | null;
  color?: string | null;
  unixtime?: number | null;
};

export type DeleteOpenInput = {
  id?: string | null;
};

export type CreateTimeInput = {
  id?: string | null;
  color?: string | null;
  open?: string | null;
  end?: string | null;
};

export type UpdateTimeInput = {
  id: string;
  color?: string | null;
  open?: string | null;
  end?: string | null;
};

export type DeleteTimeInput = {
  id?: string | null;
};

export type CreateParameterInput = {
  id?: string | null;
  name?: string | null;
  state?: string | null;
};

export type UpdateParameterInput = {
  id: string;
  name?: string | null;
  state?: string | null;
};

export type DeleteParameterInput = {
  id?: string | null;
};

export type ModelPositionFilterInput = {
  id?: ModelIDFilterInput | null;
  and?: Array<ModelPositionFilterInput | null> | null;
  or?: Array<ModelPositionFilterInput | null> | null;
  not?: ModelPositionFilterInput | null;
};

export type ModelIDFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelSeatFilterInput = {
  id?: ModelIDFilterInput | null;
  name?: ModelStringFilterInput | null;
  and?: Array<ModelSeatFilterInput | null> | null;
  or?: Array<ModelSeatFilterInput | null> | null;
  not?: ModelSeatFilterInput | null;
};

export type ModelStringFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelStateFilterInput = {
  id?: ModelIDFilterInput | null;
  name?: ModelStringFilterInput | null;
  color?: ModelStringFilterInput | null;
  and?: Array<ModelStateFilterInput | null> | null;
  or?: Array<ModelStateFilterInput | null> | null;
  not?: ModelStateFilterInput | null;
};

export type ModelOpenFilterInput = {
  id?: ModelIDFilterInput | null;
  date?: ModelStringFilterInput | null;
  time?: ModelStringFilterInput | null;
  color?: ModelStringFilterInput | null;
  unixtime?: ModelIntFilterInput | null;
  and?: Array<ModelOpenFilterInput | null> | null;
  or?: Array<ModelOpenFilterInput | null> | null;
  not?: ModelOpenFilterInput | null;
};

export type ModelIntFilterInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  contains?: number | null;
  notContains?: number | null;
  between?: Array<number | null> | null;
};

export type ModelTimeFilterInput = {
  id?: ModelIDFilterInput | null;
  color?: ModelStringFilterInput | null;
  open?: ModelStringFilterInput | null;
  end?: ModelStringFilterInput | null;
  and?: Array<ModelTimeFilterInput | null> | null;
  or?: Array<ModelTimeFilterInput | null> | null;
  not?: ModelTimeFilterInput | null;
};

export type ModelParameterFilterInput = {
  id?: ModelIDFilterInput | null;
  name?: ModelStringFilterInput | null;
  state?: ModelStringFilterInput | null;
  and?: Array<ModelParameterFilterInput | null> | null;
  or?: Array<ModelParameterFilterInput | null> | null;
  not?: ModelParameterFilterInput | null;
};

export type CreatePositionMutation = {
  __typename: "Position";
  id: string;
  seat: {
    __typename: "Seat";
    id: string;
    name: string | null;
    state: {
      __typename: "State";
      id: string;
      name: string | null;
      color: string | null;
    } | null;
  } | null;
};

export type UpdatePositionMutation = {
  __typename: "Position";
  id: string;
  seat: {
    __typename: "Seat";
    id: string;
    name: string | null;
    state: {
      __typename: "State";
      id: string;
      name: string | null;
      color: string | null;
    } | null;
  } | null;
};

export type DeletePositionMutation = {
  __typename: "Position";
  id: string;
  seat: {
    __typename: "Seat";
    id: string;
    name: string | null;
    state: {
      __typename: "State";
      id: string;
      name: string | null;
      color: string | null;
    } | null;
  } | null;
};

export type CreateSeatMutation = {
  __typename: "Seat";
  id: string;
  name: string | null;
  state: {
    __typename: "State";
    id: string;
    name: string | null;
    color: string | null;
  } | null;
};

export type UpdateSeatMutation = {
  __typename: "Seat";
  id: string;
  name: string | null;
  state: {
    __typename: "State";
    id: string;
    name: string | null;
    color: string | null;
  } | null;
};

export type DeleteSeatMutation = {
  __typename: "Seat";
  id: string;
  name: string | null;
  state: {
    __typename: "State";
    id: string;
    name: string | null;
    color: string | null;
  } | null;
};

export type CreateStateMutation = {
  __typename: "State";
  id: string;
  name: string | null;
  color: string | null;
};

export type UpdateStateMutation = {
  __typename: "State";
  id: string;
  name: string | null;
  color: string | null;
};

export type DeleteStateMutation = {
  __typename: "State";
  id: string;
  name: string | null;
  color: string | null;
};

export type CreateOpenMutation = {
  __typename: "Open";
  id: string;
  date: string | null;
  time: string | null;
  color: string | null;
  unixtime: number | null;
};

export type UpdateOpenMutation = {
  __typename: "Open";
  id: string;
  date: string | null;
  time: string | null;
  color: string | null;
  unixtime: number | null;
};

export type DeleteOpenMutation = {
  __typename: "Open";
  id: string;
  date: string | null;
  time: string | null;
  color: string | null;
  unixtime: number | null;
};

export type CreateTimeMutation = {
  __typename: "Time";
  id: string;
  color: string | null;
  open: string | null;
  end: string | null;
};

export type UpdateTimeMutation = {
  __typename: "Time";
  id: string;
  color: string | null;
  open: string | null;
  end: string | null;
};

export type DeleteTimeMutation = {
  __typename: "Time";
  id: string;
  color: string | null;
  open: string | null;
  end: string | null;
};

export type CreateParameterMutation = {
  __typename: "Parameter";
  id: string;
  name: string | null;
  state: string | null;
};

export type UpdateParameterMutation = {
  __typename: "Parameter";
  id: string;
  name: string | null;
  state: string | null;
};

export type DeleteParameterMutation = {
  __typename: "Parameter";
  id: string;
  name: string | null;
  state: string | null;
};

export type GetPositionQuery = {
  __typename: "Position";
  id: string;
  seat: {
    __typename: "Seat";
    id: string;
    name: string | null;
    state: {
      __typename: "State";
      id: string;
      name: string | null;
      color: string | null;
    } | null;
  } | null;
};

export type ListPositionsQuery = {
  __typename: "ModelPositionConnection";
  items: Array<{
    __typename: "Position";
    id: string;
    seat: {
      __typename: "Seat";
      id: string;
      name: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetSeatQuery = {
  __typename: "Seat";
  id: string;
  name: string | null;
  state: {
    __typename: "State";
    id: string;
    name: string | null;
    color: string | null;
  } | null;
};

export type ListSeatsQuery = {
  __typename: "ModelSeatConnection";
  items: Array<{
    __typename: "Seat";
    id: string;
    name: string | null;
    state: {
      __typename: "State";
      id: string;
      name: string | null;
      color: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetStateQuery = {
  __typename: "State";
  id: string;
  name: string | null;
  color: string | null;
};

export type ListStatesQuery = {
  __typename: "ModelStateConnection";
  items: Array<{
    __typename: "State";
    id: string;
    name: string | null;
    color: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetOpenQuery = {
  __typename: "Open";
  id: string;
  date: string | null;
  time: string | null;
  color: string | null;
  unixtime: number | null;
};

export type ListOpensQuery = {
  __typename: "ModelOpenConnection";
  items: Array<{
    __typename: "Open";
    id: string;
    date: string | null;
    time: string | null;
    color: string | null;
    unixtime: number | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetTimeQuery = {
  __typename: "Time";
  id: string;
  color: string | null;
  open: string | null;
  end: string | null;
};

export type ListTimesQuery = {
  __typename: "ModelTimeConnection";
  items: Array<{
    __typename: "Time";
    id: string;
    color: string | null;
    open: string | null;
    end: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetParameterQuery = {
  __typename: "Parameter";
  id: string;
  name: string | null;
  state: string | null;
};

export type ListParametersQuery = {
  __typename: "ModelParameterConnection";
  items: Array<{
    __typename: "Parameter";
    id: string;
    name: string | null;
    state: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreatePositionSubscription = {
  __typename: "Position";
  id: string;
  seat: {
    __typename: "Seat";
    id: string;
    name: string | null;
    state: {
      __typename: "State";
      id: string;
      name: string | null;
      color: string | null;
    } | null;
  } | null;
};

export type OnUpdatePositionSubscription = {
  __typename: "Position";
  id: string;
  seat: {
    __typename: "Seat";
    id: string;
    name: string | null;
    state: {
      __typename: "State";
      id: string;
      name: string | null;
      color: string | null;
    } | null;
  } | null;
};

export type OnDeletePositionSubscription = {
  __typename: "Position";
  id: string;
  seat: {
    __typename: "Seat";
    id: string;
    name: string | null;
    state: {
      __typename: "State";
      id: string;
      name: string | null;
      color: string | null;
    } | null;
  } | null;
};

export type OnCreateSeatSubscription = {
  __typename: "Seat";
  id: string;
  name: string | null;
  state: {
    __typename: "State";
    id: string;
    name: string | null;
    color: string | null;
  } | null;
};

export type OnUpdateSeatSubscription = {
  __typename: "Seat";
  id: string;
  name: string | null;
  state: {
    __typename: "State";
    id: string;
    name: string | null;
    color: string | null;
  } | null;
};

export type OnDeleteSeatSubscription = {
  __typename: "Seat";
  id: string;
  name: string | null;
  state: {
    __typename: "State";
    id: string;
    name: string | null;
    color: string | null;
  } | null;
};

export type OnCreateStateSubscription = {
  __typename: "State";
  id: string;
  name: string | null;
  color: string | null;
};

export type OnUpdateStateSubscription = {
  __typename: "State";
  id: string;
  name: string | null;
  color: string | null;
};

export type OnDeleteStateSubscription = {
  __typename: "State";
  id: string;
  name: string | null;
  color: string | null;
};

export type OnCreateOpenSubscription = {
  __typename: "Open";
  id: string;
  date: string | null;
  time: string | null;
  color: string | null;
  unixtime: number | null;
};

export type OnUpdateOpenSubscription = {
  __typename: "Open";
  id: string;
  date: string | null;
  time: string | null;
  color: string | null;
  unixtime: number | null;
};

export type OnDeleteOpenSubscription = {
  __typename: "Open";
  id: string;
  date: string | null;
  time: string | null;
  color: string | null;
  unixtime: number | null;
};

export type OnCreateTimeSubscription = {
  __typename: "Time";
  id: string;
  color: string | null;
  open: string | null;
  end: string | null;
};

export type OnUpdateTimeSubscription = {
  __typename: "Time";
  id: string;
  color: string | null;
  open: string | null;
  end: string | null;
};

export type OnDeleteTimeSubscription = {
  __typename: "Time";
  id: string;
  color: string | null;
  open: string | null;
  end: string | null;
};

export type OnCreateParameterSubscription = {
  __typename: "Parameter";
  id: string;
  name: string | null;
  state: string | null;
};

export type OnUpdateParameterSubscription = {
  __typename: "Parameter";
  id: string;
  name: string | null;
  state: string | null;
};

export type OnDeleteParameterSubscription = {
  __typename: "Parameter";
  id: string;
  name: string | null;
  state: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreatePosition(
    input: CreatePositionInput
  ): Promise<CreatePositionMutation> {
    const statement = `mutation CreatePosition($input: CreatePositionInput!) {
        createPosition(input: $input) {
          __typename
          id
          seat {
            __typename
            id
            name
            state {
              __typename
              id
              name
              color
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePositionMutation>response.data.createPosition;
  }
  async UpdatePosition(
    input: UpdatePositionInput
  ): Promise<UpdatePositionMutation> {
    const statement = `mutation UpdatePosition($input: UpdatePositionInput!) {
        updatePosition(input: $input) {
          __typename
          id
          seat {
            __typename
            id
            name
            state {
              __typename
              id
              name
              color
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePositionMutation>response.data.updatePosition;
  }
  async DeletePosition(
    input: DeletePositionInput
  ): Promise<DeletePositionMutation> {
    const statement = `mutation DeletePosition($input: DeletePositionInput!) {
        deletePosition(input: $input) {
          __typename
          id
          seat {
            __typename
            id
            name
            state {
              __typename
              id
              name
              color
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePositionMutation>response.data.deletePosition;
  }
  async CreateSeat(input: CreateSeatInput): Promise<CreateSeatMutation> {
    const statement = `mutation CreateSeat($input: CreateSeatInput!) {
        createSeat(input: $input) {
          __typename
          id
          name
          state {
            __typename
            id
            name
            color
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSeatMutation>response.data.createSeat;
  }
  async UpdateSeat(input: UpdateSeatInput): Promise<UpdateSeatMutation> {
    const statement = `mutation UpdateSeat($input: UpdateSeatInput!) {
        updateSeat(input: $input) {
          __typename
          id
          name
          state {
            __typename
            id
            name
            color
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSeatMutation>response.data.updateSeat;
  }
  async DeleteSeat(input: DeleteSeatInput): Promise<DeleteSeatMutation> {
    const statement = `mutation DeleteSeat($input: DeleteSeatInput!) {
        deleteSeat(input: $input) {
          __typename
          id
          name
          state {
            __typename
            id
            name
            color
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSeatMutation>response.data.deleteSeat;
  }
  async CreateState(input: CreateStateInput): Promise<CreateStateMutation> {
    const statement = `mutation CreateState($input: CreateStateInput!) {
        createState(input: $input) {
          __typename
          id
          name
          color
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateStateMutation>response.data.createState;
  }
  async UpdateState(input: UpdateStateInput): Promise<UpdateStateMutation> {
    const statement = `mutation UpdateState($input: UpdateStateInput!) {
        updateState(input: $input) {
          __typename
          id
          name
          color
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateStateMutation>response.data.updateState;
  }
  async DeleteState(input: DeleteStateInput): Promise<DeleteStateMutation> {
    const statement = `mutation DeleteState($input: DeleteStateInput!) {
        deleteState(input: $input) {
          __typename
          id
          name
          color
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteStateMutation>response.data.deleteState;
  }
  async CreateOpen(input: CreateOpenInput): Promise<CreateOpenMutation> {
    const statement = `mutation CreateOpen($input: CreateOpenInput!) {
        createOpen(input: $input) {
          __typename
          id
          date
          time
          color
          unixtime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOpenMutation>response.data.createOpen;
  }
  async UpdateOpen(input: UpdateOpenInput): Promise<UpdateOpenMutation> {
    const statement = `mutation UpdateOpen($input: UpdateOpenInput!) {
        updateOpen(input: $input) {
          __typename
          id
          date
          time
          color
          unixtime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOpenMutation>response.data.updateOpen;
  }
  async DeleteOpen(input: DeleteOpenInput): Promise<DeleteOpenMutation> {
    const statement = `mutation DeleteOpen($input: DeleteOpenInput!) {
        deleteOpen(input: $input) {
          __typename
          id
          date
          time
          color
          unixtime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOpenMutation>response.data.deleteOpen;
  }
  async CreateTime(input: CreateTimeInput): Promise<CreateTimeMutation> {
    const statement = `mutation CreateTime($input: CreateTimeInput!) {
        createTime(input: $input) {
          __typename
          id
          color
          open
          end
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTimeMutation>response.data.createTime;
  }
  async UpdateTime(input: UpdateTimeInput): Promise<UpdateTimeMutation> {
    const statement = `mutation UpdateTime($input: UpdateTimeInput!) {
        updateTime(input: $input) {
          __typename
          id
          color
          open
          end
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTimeMutation>response.data.updateTime;
  }
  async DeleteTime(input: DeleteTimeInput): Promise<DeleteTimeMutation> {
    const statement = `mutation DeleteTime($input: DeleteTimeInput!) {
        deleteTime(input: $input) {
          __typename
          id
          color
          open
          end
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTimeMutation>response.data.deleteTime;
  }
  async CreateParameter(
    input: CreateParameterInput
  ): Promise<CreateParameterMutation> {
    const statement = `mutation CreateParameter($input: CreateParameterInput!) {
        createParameter(input: $input) {
          __typename
          id
          name
          state
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateParameterMutation>response.data.createParameter;
  }
  async UpdateParameter(
    input: UpdateParameterInput
  ): Promise<UpdateParameterMutation> {
    const statement = `mutation UpdateParameter($input: UpdateParameterInput!) {
        updateParameter(input: $input) {
          __typename
          id
          name
          state
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateParameterMutation>response.data.updateParameter;
  }
  async DeleteParameter(
    input: DeleteParameterInput
  ): Promise<DeleteParameterMutation> {
    const statement = `mutation DeleteParameter($input: DeleteParameterInput!) {
        deleteParameter(input: $input) {
          __typename
          id
          name
          state
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteParameterMutation>response.data.deleteParameter;
  }
  async GetPosition(id: string): Promise<GetPositionQuery> {
    const statement = `query GetPosition($id: ID!) {
        getPosition(id: $id) {
          __typename
          id
          seat {
            __typename
            id
            name
            state {
              __typename
              id
              name
              color
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPositionQuery>response.data.getPosition;
  }
  async ListPositions(
    filter?: ModelPositionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPositionsQuery> {
    const statement = `query ListPositions($filter: ModelPositionFilterInput, $limit: Int, $nextToken: String) {
        listPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            seat {
              __typename
              id
              name
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPositionsQuery>response.data.listPositions;
  }
  async GetSeat(id: string): Promise<GetSeatQuery> {
    const statement = `query GetSeat($id: ID!) {
        getSeat(id: $id) {
          __typename
          id
          name
          state {
            __typename
            id
            name
            color
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSeatQuery>response.data.getSeat;
  }
  async ListSeats(
    filter?: ModelSeatFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSeatsQuery> {
    const statement = `query ListSeats($filter: ModelSeatFilterInput, $limit: Int, $nextToken: String) {
        listSeats(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            state {
              __typename
              id
              name
              color
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSeatsQuery>response.data.listSeats;
  }
  async GetState(id: string): Promise<GetStateQuery> {
    const statement = `query GetState($id: ID!) {
        getState(id: $id) {
          __typename
          id
          name
          color
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStateQuery>response.data.getState;
  }
  async ListStates(
    filter?: ModelStateFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListStatesQuery> {
    const statement = `query ListStates($filter: ModelStateFilterInput, $limit: Int, $nextToken: String) {
        listStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            color
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListStatesQuery>response.data.listStates;
  }
  async GetOpen(id: string): Promise<GetOpenQuery> {
    const statement = `query GetOpen($id: ID!) {
        getOpen(id: $id) {
          __typename
          id
          date
          time
          color
          unixtime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOpenQuery>response.data.getOpen;
  }
  async ListOpens(
    filter?: ModelOpenFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOpensQuery> {
    const statement = `query ListOpens($filter: ModelOpenFilterInput, $limit: Int, $nextToken: String) {
        listOpens(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            date
            time
            color
            unixtime
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOpensQuery>response.data.listOpens;
  }
  async GetTime(id: string): Promise<GetTimeQuery> {
    const statement = `query GetTime($id: ID!) {
        getTime(id: $id) {
          __typename
          id
          color
          open
          end
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTimeQuery>response.data.getTime;
  }
  async ListTimes(
    filter?: ModelTimeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTimesQuery> {
    const statement = `query ListTimes($filter: ModelTimeFilterInput, $limit: Int, $nextToken: String) {
        listTimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            color
            open
            end
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTimesQuery>response.data.listTimes;
  }
  async GetParameter(id: string): Promise<GetParameterQuery> {
    const statement = `query GetParameter($id: ID!) {
        getParameter(id: $id) {
          __typename
          id
          name
          state
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetParameterQuery>response.data.getParameter;
  }
  async ListParameters(
    filter?: ModelParameterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListParametersQuery> {
    const statement = `query ListParameters($filter: ModelParameterFilterInput, $limit: Int, $nextToken: String) {
        listParameters(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            state
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListParametersQuery>response.data.listParameters;
  }
  OnCreatePositionListener: Observable<
    OnCreatePositionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePosition {
        onCreatePosition {
          __typename
          id
          seat {
            __typename
            id
            name
            state {
              __typename
              id
              name
              color
            }
          }
        }
      }`
    )
  ) as Observable<OnCreatePositionSubscription>;

  OnUpdatePositionListener: Observable<
    OnUpdatePositionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePosition {
        onUpdatePosition {
          __typename
          id
          seat {
            __typename
            id
            name
            state {
              __typename
              id
              name
              color
            }
          }
        }
      }`
    )
  ) as Observable<OnUpdatePositionSubscription>;

  OnDeletePositionListener: Observable<
    OnDeletePositionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePosition {
        onDeletePosition {
          __typename
          id
          seat {
            __typename
            id
            name
            state {
              __typename
              id
              name
              color
            }
          }
        }
      }`
    )
  ) as Observable<OnDeletePositionSubscription>;

  OnCreateSeatListener: Observable<OnCreateSeatSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateSeat {
        onCreateSeat {
          __typename
          id
          name
          state {
            __typename
            id
            name
            color
          }
        }
      }`
    )
  ) as Observable<OnCreateSeatSubscription>;

  OnUpdateSeatListener: Observable<OnUpdateSeatSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSeat {
        onUpdateSeat {
          __typename
          id
          name
          state {
            __typename
            id
            name
            color
          }
        }
      }`
    )
  ) as Observable<OnUpdateSeatSubscription>;

  OnDeleteSeatListener: Observable<OnDeleteSeatSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSeat {
        onDeleteSeat {
          __typename
          id
          name
          state {
            __typename
            id
            name
            color
          }
        }
      }`
    )
  ) as Observable<OnDeleteSeatSubscription>;

  OnCreateStateListener: Observable<OnCreateStateSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateState {
        onCreateState {
          __typename
          id
          name
          color
        }
      }`
    )
  ) as Observable<OnCreateStateSubscription>;

  OnUpdateStateListener: Observable<OnUpdateStateSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateState {
        onUpdateState {
          __typename
          id
          name
          color
        }
      }`
    )
  ) as Observable<OnUpdateStateSubscription>;

  OnDeleteStateListener: Observable<OnDeleteStateSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteState {
        onDeleteState {
          __typename
          id
          name
          color
        }
      }`
    )
  ) as Observable<OnDeleteStateSubscription>;

  OnCreateOpenListener: Observable<OnCreateOpenSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateOpen {
        onCreateOpen {
          __typename
          id
          date
          time
          color
          unixtime
        }
      }`
    )
  ) as Observable<OnCreateOpenSubscription>;

  OnUpdateOpenListener: Observable<OnUpdateOpenSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOpen {
        onUpdateOpen {
          __typename
          id
          date
          time
          color
          unixtime
        }
      }`
    )
  ) as Observable<OnUpdateOpenSubscription>;

  OnDeleteOpenListener: Observable<OnDeleteOpenSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOpen {
        onDeleteOpen {
          __typename
          id
          date
          time
          color
          unixtime
        }
      }`
    )
  ) as Observable<OnDeleteOpenSubscription>;

  OnCreateTimeListener: Observable<OnCreateTimeSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateTime {
        onCreateTime {
          __typename
          id
          color
          open
          end
        }
      }`
    )
  ) as Observable<OnCreateTimeSubscription>;

  OnUpdateTimeListener: Observable<OnUpdateTimeSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTime {
        onUpdateTime {
          __typename
          id
          color
          open
          end
        }
      }`
    )
  ) as Observable<OnUpdateTimeSubscription>;

  OnDeleteTimeListener: Observable<OnDeleteTimeSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTime {
        onDeleteTime {
          __typename
          id
          color
          open
          end
        }
      }`
    )
  ) as Observable<OnDeleteTimeSubscription>;

  OnCreateParameterListener: Observable<
    OnCreateParameterSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateParameter {
        onCreateParameter {
          __typename
          id
          name
          state
        }
      }`
    )
  ) as Observable<OnCreateParameterSubscription>;

  OnUpdateParameterListener: Observable<
    OnUpdateParameterSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateParameter {
        onUpdateParameter {
          __typename
          id
          name
          state
        }
      }`
    )
  ) as Observable<OnUpdateParameterSubscription>;

  OnDeleteParameterListener: Observable<
    OnDeleteParameterSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteParameter {
        onDeleteParameter {
          __typename
          id
          name
          state
        }
      }`
    )
  ) as Observable<OnDeleteParameterSubscription>;
}
