import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Seat } from '../models/seat';
import { formatDate } from '@angular/common';
import { APIService } from '../API.service';

import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';

import { Storage } from 'aws-amplify';

import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-show-inquiry',
  templateUrl: './show-inquiry.component.html',
  styleUrls: ['./show-inquiry.component.css']
})
export class ShowInquiryComponent implements OnInit {

  public today = new Date;
  public openTime : string;
  public emptyNum : string;
  private allNum : string;
  private seats: any[];
  public images: string;
  public atoVisible: boolean;
  public numVisible: boolean;

  private emptyNumClass: string;
  
  spinner = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });

  ngOnInit() {
    this.openTime = '00:00～24:00'
    this.atoVisible = true;
    this.numVisible = true;
  }
  
  constructor(
    private api: APIService,
    @Inject(LOCALE_ID) private locale: string,
    private overlay: Overlay,
    private router: Router )
  {
    this.images = '';
    this.emptyNumClass = 'empty-num';
    this.loadData();
  }

  public loadData() {
    this.spinner.attach(new ComponentPortal(MatSpinner));
    this.api.ListSeats(null,100,null).then(data => {
      // 日付
      this.today = new Date();

      // 開館時間
      const openId = formatDate(this.today, "yyyyMMdd", this.locale);
      this.api.GetOpen(openId).then(item=>{
        if(item === null){
          this.openTime = '休館日';
        }
        else{
          this.openTime = item.time;
        }

        this.atoVisible = true;
        this.numVisible = true;
  
        // 全席数
        this.allNum = String(data.items.length);
  
        // 空席数
        const emptySeats = data.items.filter((emptySeat) => emptySeat.state.id == '1');
        this.emptyNum = String(emptySeats.length);
  
        // 画像
        this.setImg();
        this.spinner.detach();
      });
    });
  }

  setImg(){
    //閉館時間    
    if(this.getClosed()){
      this.images = "assets/images/show0.png"
      this.emptyNumClass = "empty-num6";
      this.emptyNum = '0';
      this.atoVisible = false;
      this.numVisible = false;
      return;
    }


    const val = Number(this.emptyNum)/Number(this.allNum);

    if(val > 0.8){
      this.images = "assets/images/show1.png"
      this.emptyNumClass = "empty-num1";
    }else if(val > 0.6){
      this.images = "assets/images/show2.png"
      this.emptyNumClass = "empty-num2";
    }else if(val > 0.4){
      this.images = "assets/images/show3.png"
      this.emptyNumClass = "empty-num3";
    }else if(val > 0.2){
      this.images = "assets/images/show4.png"
      this.emptyNumClass = "empty-num4";
    }else if(val > 0){
      this.images = "assets/images/show5.png"
      this.emptyNumClass = "empty-num5";
    }else if(val == 0){
      this.images = "assets/images/show6.png"
      this.emptyNumClass = "empty-num6";
      this.emptyNum = '満席';
      this.atoVisible = false;
    }
  }

  getClosed(): boolean{
    if(this.openTime == '休館日'){
      return true;
    }

    const other = this.openTime.split('～');
    const open = other[0];
    const end = other[1];
    const now = ('0' + this.today.getHours()).slice(-2) + ':' + ('0' + this.today.getMinutes()).slice(-2);

    if(now < open || end < now){
      return true;
    }

    return false;
  }

  onNavigate(){
    this.router.navigateByUrl("/seat-inquiry");
  }
}
