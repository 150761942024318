<app-nav>
    <div class="grid-container">
        <h1 class="mat-h1">ホーム</h1>
        <mat-grid-list cols="3" rowHeight="250px">
          <mat-grid-tile *ngFor="let card of cards | async" colspan="1" rowspan="1">
            <mat-card class="dashboard-card" fxLayout="column">
              <mat-card-header class="card-header-underline">
                <mat-card-title>
                  {{card.title}}
                  <!-- <div mat-card-avatar [class]="'card-header-' + card.color"></div> -->
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="dashboard-card-content" fxFlex="50%">
                {{card.content}}
              </mat-card-content>
              <mat-card-actions align="end">
                  <button mat-raised-button color="primary" (click)='onNavigate(card.routing)'>開く</button>
              </mat-card-actions>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
</app-nav>
