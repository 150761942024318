<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/dashboard']">ホーム</a>
      <a mat-list-item [routerLink]="['/show-inquiry']">空席状況確認</a>
      <a mat-list-item [routerLink]="['/seat-regist']">利用者登録</a>
      <a mat-list-item [routerLink]="['/seats-setting']">座席設定</a>
      <a mat-list-item [routerLink]="['/open-setting']">開館時間設定</a>
      <a mat-list-item [routerLink]="['/layout-setting']">座席レイアウト設定</a>
      <a mat-list-item [routerLink]="['/file-setting']">関連ファイル設定</a>
      <a mat-list-item (click)="logout()">ログアウト</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>すたでぃばんく 管理</span>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>