import {Seat} from './seat';
import {State} from './state';
import { isNull } from 'util';

export class Position {
    id: string;
    seat: {
      id:string;
      name:string;
    };

    
  constructor(id: string, seat: Seat) {
    this.id = id;
    if(isNull(seat)){
      this.seat = null;
    }
    else{
      this.seat = {id:seat.id,name:seat.name}
    }
  }
  }