<app-nav>
    <div class="grid-container">
        <h1 class="mat-h1">開館時間設定</h1>
        <div fxLayout="row">
            <div fxFlex="50%">
                <mat-card>
                    <mat-card-header class="card-header-underline">
                        <mat-card-title>
                            開館時間一覧
                        </mat-card-title>
                    </mat-card-header>
                    <mat-calendar [minDate]="minDate" [maxDate]="maxDate" [dateClass]="dateClass()"
                        [selected]="selectedDate" (selectedChange)="onSelect($event)"></mat-calendar>
                </mat-card>
            </div>
            <div fxFlex="50%">
                <mat-card>
                    <mat-card-header class="card-header-underline">
                        <mat-card-title>
                            開館時間設定
                        </mat-card-title>
                    </mat-card-header>
                    <form (ngSubmit)="onSubmit()" class="login-form" #openForm="ngForm">
                        <mat-form-field>
                            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
                                placeholder="Choose a date" [value]="selectedDate" name="selectedDate" id="selectedDate" [(ngModel)]="selectedDate" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            [(ngModel)]="selectedTime" name="selectedTime" id="selectedTime" required>

                            <div fxLayout="row" *ngFor="let time of times">
                                <div fxFlex="50%">
                                    <mat-radio-button class="example-radio-button" [value]="time">
                                        <div fxLayout="row">
                                            <div [class]="'radio-color ' + getHighlightColor(time.color)"></div>
                                            <b>{{time.open}}～{{time.end}}</b>
                                        </div>
                                    </mat-radio-button>
                                </div>
                            </div>
                            <div fxLayout="row">
                                <div fxFlex="40%">
                                    <mat-radio-button class="example-radio-button" [value]="otherTime">
                                        <div fxLayout="row">
                                            <div [class]="'radio-color ' + getHighlightColor(otherTime.color)"></div>
                                            <b>その他</b>
                                        </div>
                                    </mat-radio-button>
                                </div>
                                <div fxFlex="60%">
                                    <div fxLayout="column">
                                        <div fxLayout="row">
                                            <b>開館 : </b>
                                            <ngx-timepicker-field [format]="24" [defaultTime]="otherOpen"
                                                [(ngModel)]="otherOpen" name="otherOpen" id="otherOpen" >
                                            </ngx-timepicker-field>
                                        </div>
                                        <div fxLayout="row">
                                            <b>閉館 : </b>
                                            <ngx-timepicker-field [format]="24" [defaultTime]="otherEnd"
                                                [(ngModel)]="otherEnd" name="otherEnd" id="otherEnd" ></ngx-timepicker-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="50%">
                                <mat-radio-button class="example-radio-button" [value]="closedTime">
                                    <div fxLayout="row">
                                        <div [class]="'radio-color ' + getHighlightColor(closedTime.color)"></div>
                                        <b>休館日</b>
                                    </div>
                                </mat-radio-button>
                            </div>
                        </mat-radio-group>
                        <button mat-raised-button color="warn" type="submit" [disabled]="openForm.invalid">更新</button>
                    </form>
                </mat-card>
            </div>
        </div>
    </div>
</app-nav>