// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "awsorihime-20190725104456-hostingbucket-sample",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://dzf1irgbabmg8.cloudfront.net",
    "aws_cognito_identity_pool_id": "ap-northeast-1:eed694a5-f162-46ec-a142-77ed3c35d72d",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_X0CPZI44i",
    "aws_user_pools_web_client_id": "2d6r0mc2kcgvgjhh9vmfmi771q",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://sik2wk6panbl7hlva453qtvdbe.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_user_files_s3_bucket": "awsorihime24ea86d278cb4a44a16dd0c0c7e4a1e0-sample",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
