<div class="bg">
    <div class="container">
        <div fxLayout="column" class="main">
            <div fxFlex="15">
                <div class="title-center" fxLayout="column">
                    <div class="title-row" fxFlex="50">すたでぃばんく</div>
                    <div class="title-row" fxFlex="50">座席状況</div>
                </div>
            </div>
            <div fxFlex="70">
                <mat-card class="vcenter"  style="padding: 5px">
                    <mat-card-content>
                        <div class="seats">
                            <mat-grid-list cols="{{colNum}}" rowHeight="{{rowHeight}}">
                                <mat-grid-tile *ngFor="let position of positions" colspan="1" rowspan="1"
                                    [style.background]="checkSelected(position)">
                                    {{getPositionName(position)}}
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div fxFlex="5">
            </div>
        </div> 
    </div>
    <div class="footer" style="text-align: center;">
        <button mat-raised-button color="primary" (click)='onNavigate()'>空席状況を表示</button>
    </div> 
    <button mat-fab color="warn" (click)='loadData()' class="md-fab-reload">
        <mat-icon>refresh</mat-icon>
    </button>
</div>