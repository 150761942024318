import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { Storage } from 'aws-amplify';

@Component({
  selector: 'app-show-nav',
  templateUrl: './show-nav.component.html',
  styleUrls: ['./show-nav.component.css']
})
export class ShowNavComponent {

  uploadFiles;
  test = new Observable<boolean>();

  isHandset$: Observable<boolean> = of(true);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.loadData();
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => !result.matches),
      share()
    );
  }

  loadData() {
    Storage.list('')
      .then(result => {
        this.uploadFiles = result;
      })
      .catch(err => console.log(err));
  }
  

  downloadItem(row: any) {
    Storage.get(row.key)
      .then(result => {
        window.location.href = String(result);
      })
      .catch(err => console.log(err));
  }

}
