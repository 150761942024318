
<app-nav>
  <div class="grid-container">
    <h1 class="mat-h1">関連ファイル設定</h1>
      <div class="container">
          <mat-card>
      <input type="file" style="display: none" #fileInput accept="image/*" (change)="onChangeFileInput()" />
      <button mat-raised-button color="primary" class="file-select-button" (click)="onClickFileInputButton()">
        <mat-icon>attach_file</mat-icon>
        ファイルを選択
      </button>
    
      <p class="file-name" *ngIf="!file; else fileName">ファイルが選択されていません</p>
      <ng-template #fileName>
        <p class="file-name">{{ file?.name }}</p>
      </ng-template>
    
      <button mat-raised-button color="warn" (click)="onClickUpload()" class="file-upload-button">アップロード</button>
    </mat-card>
  </div>
    <br>
    <div class="row">
      <div class="container mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource" matSort class="mat-cell">

          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header color="primary">Id</mat-header-cell>
            <mat-cell *matCellDef="let row;let i = index;">{{i+1}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>ファイル名</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.key}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="fileSize">
            <mat-header-cell *matHeaderCellDef mat-sort-header>ファイルサイズ</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{getSize(row.size)}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="uploadDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>更新日</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.lastModified | date:"yyyy/MM/dd HH:mm:ss"}}</mat-cell>
          </ng-container>

          <!-- actions -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef >
              <span class="spacer"></span>
              Reload data:
              <button mat-icon-button (click)="refresh()">
                <mat-icon>refresh</mat-icon>
              </button>
            </mat-header-cell>

            <mat-cell *matCellDef="let row; let i=index;">
              <button mat-icon-button color="accent" (click)="downloadItem(row)">
                <mat-icon aria-label="Download">get_app</mat-icon>
              </button>

              <button mat-icon-button color="accent" (click)="deleteItem(row)">
                <mat-icon aria-label="Delete">delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </div>

    </div>
  </div>
</app-nav>