<app-nav>
    <div class="grid-container">
        <h1 class="mat-h1">座席表設定</h1>
        <mat-card>
            <mat-card-content>
                <div fxLayout="row">
                    <div fxFlex="50%">
                        <mat-card class="vcenter">
                            <mat-card-title>
                                座席レイアウト
                            </mat-card-title>
                            <mat-card-content>
                                <div class="seats">
                                    <mat-grid-list cols="{{colNum}}" rows="rowNum" rowHeight="5vh">
                                        <mat-grid-tile *ngFor="let position of positions" colspan="1" rowspan="1"
                                            [style.background]="checkSelected(position)" (click)="clickTile(position)">
                                            {{getPositionName(position)}}
                                        </mat-grid-tile>
                                    </mat-grid-list>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div fxFlex="50%">
                        <mat-card>
                            <mat-card-title>
                                座席レイアウト設定
                            </mat-card-title>
                            <mat-card>
                                <mat-card-header class="card-header-underline">
                                    <mat-card-title>
                                        座席数
                                    </mat-card-title>
                                </mat-card-header>
                                <form (ngSubmit)="onSubmit()" class="login-form" #matrixForm="ngForm">
                                        <br>
                                    <mat-form-field>
                                        <input type='number' [min]="1" [max]="20" matInput placeholder="行数" name="colNumber
                                " [value]="colNumber" [(ngModel)]="colNumber">
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field>
                                        <input type='number' [min]="1" [max]="20" matInput placeholder="列数" name="rowNumber
                                " [value]="rowNumber" [(ngModel)]="rowNumber">
                                    </mat-form-field>
                                    <br>
                                    <button mat-raised-button color="primary" type="submit" class=""
                                        [disabled]="matrixForm.invalid">適用</button>
                                </form>
                            </mat-card>
                            <br>
                            <mat-card>
                                <mat-card-header class="card-header-underline">
                                    <mat-card-title>
                                        座席設定
                                    </mat-card-title>
                                </mat-card-header>
                                <form (ngSubmit)="onSeat()" class="login-form" #matrixForm="ngForm">
                                        <br>
                                    <mat-form-field>
                                        <mat-select placeholder="座席" name="selectedSeat" [(ngModel)]="selectedSeat">
                                            <mat-option [value]="null">未選択</mat-option>
                                            <mat-option *ngFor="let seat of seats" [value]="seat">
                                                <pre>{{getOption(seat)}}</pre>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <br>
                                    <button mat-raised-button color="primary" type="submit"
                                        [disabled]="matrixForm.invalid">適用</button>
                                </form>
                            </mat-card>
                        </mat-card>
                    </div>
                </div>
            </mat-card-content>
            <br>
            <button mat-raised-button class="" color="warn" (click)="clickUpdate()">更新</button>
            <button mat-raised-button class="return" (click)="clickReset()">元に戻す</button>
        </mat-card>
    </div>
</app-nav>