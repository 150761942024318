import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  /** Based on the screen size, switch from standard to one column per row */
  test = 0;
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        this.test = 1;
        return [
          { title: '空席状況照会', content: 'すたでぃばんく内の座席の空席状況を確認します。', routing: '/show-inquiry', color: 'blue', cols: 3, rows: 1 },
          { title: '利用者登録', content: '各座席の空席状況を変更します。', color: 'blue', routing: '/seat-regist', cols: 3, rows: 1 },
          { title: '座席設定', content: '利用者登録画面に表示する座席の設定を行います。', color: 'green', routing: '/seats-setting', cols: 3, rows: 1 },
          { title: '座席レイアウト設定', content: '利用者登録画面に表示する座席レイアウト設定を行います。', color: 'green', routing: '/layout-setting', cols: 3, rows: 1 },
          { title: '開館時間設定', content: '空席状況確認画面に表示する開館時間の設定を行います。', color: 'green', routing: '/open-setting', cols: 3, rows: 1 },
          { title: '関連ファイル設定', content: '空席状況確認画面のメニューに表示する関連ファイルの設定を行います。', color: 'green', routing: '/file-setting', cols: 3, rows: 1 }
        ];
      }

      return [
        { title: '利用状況照会', content: 'すたでぃばんく内の座席の空席状況を確認します。', color: 'blue', routing: '/show-inquiry', cols: 2, rows: 1 },
        { title: '利用者登録', content: '各座席の空席状況を変更します。', color: 'blue', routing: '/seat-regist', cols: 2, rows: 1 },
        { title: '座席設定', content: '利用者登録画面に表示する座席の設定を行います。', color: 'green', routing: '/seats-setting', cols: 2, rows: 1 },
        { title: '座席レイアウト設定', content: '利用者登録画面に表示する座席レイアウト設定を行います。', color: 'green', routing: '/layout-setting', cols: 3, rows: 1 },
        { title: '開館時間設定', content: '空席状況確認画面に表示する開館時間の設定を行います。', color: 'green', routing: '/open-setting', cols: 2, rows: 1 },
        { title: '関連ファイル設定', content: '空席状況確認画面のメニューに表示する関連ファイルの設定を行います。', color: 'green', routing: '/file-setting', cols: 2
        , rows: 1 }
      ];
    })
  );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router) {}

  onNavigate(routing:string){
    this.router.navigateByUrl(routing);
  }
}
