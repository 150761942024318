import { Component, OnInit, ViewChild } from '@angular/core';

import { Storage } from 'aws-amplify';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-file-setting',
  templateUrl: './file-setting.component.html',
  styleUrls: ['./file-setting.component.css']
})
export class FileSettingComponent implements OnInit {

  @ViewChild('fileInput') fileInput;
  uploadFiles;
  displayedColumns = ['id', 'fileName', 'fileSize', 'uploadDate', 'actions'];
  SeatData: [];
  StateData: [];
  dataSource: MatTableDataSource<any>;
  file: File | null = null;
  List: { key: any, eTag: any; lastModified: any; size: any };

  constructor() {
    const test = [{ key: '', eTag: '', lastModified: '', size: '' }];
    this.dataSource = new MatTableDataSource<any>(test);
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    Storage.list('')
      .then(result => {
        console.log(result);
        this.uploadFiles = result;
        this.dataSource = new MatTableDataSource<[]>(this.uploadFiles);
      })
      .catch(err => console.log(err));
  }

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.file = files[0];
  }

  onClickUpload() {

    Storage.put(this.file.name, this.file)
      .then(result => {
        console.log(result);
        this.loadData();
      })
      .catch(err => console.log(err));
  }

  getSize(size: any) {
    if (size === 0) return '0 Bytes';

    const k = 1024;
    const dm = 2 < 0 ? 0 : 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(size) / Math.log(k));

    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  downloadItem(row: any) {
    Storage.get(row.key)
      .then(result => {
        console.log(result);
        window.location.href = String(result);
      })
      .catch(err => console.log(err));
  }

  deleteItem(row: any) {
    Storage.remove(row.key)
      .then(result => {
        console.log(result);
        this.loadData();
      })
      .catch(err => console.log(err));
  }
}
