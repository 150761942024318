import { Component, OnInit } from '@angular/core';

import { isNull, isBoolean } from 'util';
import { APIService } from 'app/API.service';
import { Position } from '../models/position';
import { State } from '../models/state';
import { Seat } from '../models/seat';

import { state } from '@angular/animations';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';

import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-seat-inquiry',
  templateUrl: './seat-inquiry.component.html',
  styleUrls: ['./seat-inquiry.component.css']
})
export class SeatInquiryComponent implements OnInit {

  private seats: Seat[];
  public status: State[];
  public positions : Position[];

  state0 = new State('0','','white')
  seat0 = new Seat('0', '', this.state0);
  position0 = new Position('0', this.seat0);

  public colNum: number;
  public rowNum: number;
  public rowHeight: string;
  public selected: any;
  
  spinner = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });


  constructor(private api: APIService,
    private router: Router,
    private overlay: Overlay ) { 
    this.seats = [this.seat0]; 
    this.positions = [this.position0]; 
    this.status = [this.state0];
    this.selected = {position:this.position0, state: this.state0 };

    this.loadData();
  }

  ngOnInit() {
  }

  public loadData() {
    this.spinner.attach(new ComponentPortal(MatSpinner));
    this.api.ListParameters(null,100,null).then(parm =>{
      this.colNum = Number(parm.items.find(p => p.name == "colNumber").state);
      this.rowNum = Number(parm.items.find(p => p.name == "rowNumber").state);
      const height = Math.floor(60/this.rowNum);
      this.rowHeight = String(height) + 'vh'
      this.api.ListSeats(null,100,null).then(seat =>{
        this.seats = seat.items;
        this.api.ListStates(null,100,null).then(states => {
          this.status = states.items;
          this.api.ListPositions(null,200,null).then(positions => {
            this.positions  = positions.items.sort((a,b)=>{
              return Number(a.id) - Number(b.id);
            });
            
        this.spinner.detach();
          });
        });
      });
    });
  }
  
  checkSelected(position: Position){
    if(isNull(position.seat)){
      return 'white';
    }
    if(position.seat.id == '0'){
      return 'white';
    }

    return this.getSeatState(position).color;
  }

  getPositionName(position:Position){
    if(isNull(position.seat)){
      return '';
    }
    return position.seat.name
  }

  getSeatState(position:Position){
    if(isNull(position.seat)){
      return null;
    }
    return this.seats.find(s => s.id == position.seat.id).state
  }

  onNavigate(){
    this.router.navigateByUrl("/show-inquiry");
  }
}
